<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
            style="width: 36px; height: 36.34px"
            :src="$themeConfig.app.appLogoImage"
            alt="logo"
        />
        <h2 class="brand-text text-primary ml-1">
          MMO VPS
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
          >
<!--            <b-card-title-->
<!--              class="mb-1 font-weight-bold"-->
<!--              title-tag="h2"-->
<!--            >-->
<!--              Welcome to Vuexy! 👋-->
<!--            </b-card-title>-->
<!--            <b-card-text class="mb-2">-->
<!--              Please sign-in to your account and start the adventure-->
<!--            </b-card-text>-->

            <!-- form -->
            <validation-observer
              ref="loginForm"
              #default="{invalid}"
            >
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="login"
              >

                <!-- username -->
                <b-form-group
                  :label="$t('login.label.username')"
                  label-for="login-username"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('login.label.username')"
                    vid="username"
                    rules="required|min:3|max:255|alpha_dash"
                  >
                    <b-input-group
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="UserIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="login-username"
                        v-model="username"
                        :state="errors.length > 0 ? false:null"
                        name="login-username"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">{{$t('login.label.password')}}</label>
<!--                    <b-link :to="{name:'auth-forgot-password'}">-->
<!--                      <small>{{$t('login.button.pw_forgot')}}?</small>-->
<!--                    </b-link>-->
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('login.label.password')"
                    vid="password"
                    rules="required"
                  >
                    <b-input-group
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="LockIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        type="password"
                        name="login-password"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- checkbox -->
                <b-form-group>
                  <b-form-checkbox
                    id="remember-me"
                    v-model="status"
                    name="checkbox-1"
                  >
                    {{$t('login.button.pw_remember')}}
                  </b-form-checkbox>
                </b-form-group>

                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="invalid || isSigning"
                >
                  <b-spinner
                    v-if="isSigning"
                    class="mr-50"
                    small
                  />
                  <feather-icon
                    v-else
                    icon="LogInIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">{{$t('login.button.sign_in')}}</span>
                </b-button>

              </b-form>
            </validation-observer>

<!--            <b-card-text class="text-center mt-2">-->
<!--              <span>{{$t('login.label.new_once')}}? </span>-->
<!--              <b-link :to="{name:'auth-register'}">-->
<!--                <span>&nbsp;{{$t('login.label.register')}}</span>-->
<!--              </b-link>-->
<!--            </b-card-text>-->
          </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BSpinner, BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupPrepend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, VBTooltip
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import store from '@/store/index'

import dataRequester from '@/mixins/utils/dataRequester'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import md5 from 'md5'
import {$themeConfig} from "@themeConfig";

export default {
  directives: {
    'b-tooltip': VBTooltip
  },
  components: {
    BSpinner,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver
  },
  mixins: [dataRequester],
  data () {
    return {
      status: '',
      password: '',
      username: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      isSigning: false,

      // validation rules
      required,
      email
    }
  },
  computed: {
    $themeConfig() {
      return $themeConfig
    },
    imgUrl () {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    }
  },
  methods: {
    login () {
      const self = this
      this.$refs.loginForm.validate().then(success => {
        self.isSigning = true
        if (success) {
          self.runApi(useJwt.login({
            username: this.username,
            password: md5(this.password)
          }), function (result) {
            useJwt.setToken(result.data.accessToken)
            self.$router.replace('/').then(
              self.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: self.$t('axios.title.success'),
                  icon: 'CoffeeIcon',
                  variant: `${result.status}`,
                  text: self.$te(`axios.success.${result.message}`) ? self.$t(`axios.success.${result.message}`) : result.message
                }
              })
            )
          },
          null,
          function () {
            self.isSigning = false
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
